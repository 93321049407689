<template>
  <div>
    <div>
      <img src="../../assets/slides/Slide36.jpeg" />
    </div>
    <div class="text-center pad-top">
      <router-link to="/module-two-quiz">
        <button
          class="button rounded lower">
          Go to module quiz
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTwoPageEight',
};
</script>
