<template>
  <div>
    <img src="../../assets/slides/Slide24.jpeg" />
  </div>
</template>
  
<script>
export default {
  name: 'ModuleTwoPageTwo',
};
 </script>
