<template>
  <div>
    <div v-if="!showExtra" class="relative pad-top">
      <ul class="mini-tab">
        <li class="active">Part 1</li>
        <li @click="showExtra = true">Part 2</li>
      </ul>
      <img src="../../assets/slides/Slide26.jpeg" />
    </div>
    <div v-else class="relative pad-top">
      <ul class="mini-tab">
        <li @click="showExtra = false">Part 1</li>
        <li class="active">Part 2</li>
      </ul>
      <img src="../../assets/slides/Slide27.jpeg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTwoPageFour',
  data() {
    return {
      showExtra: false,
    };
  },
};
</script>
