<template>
  <div>
    <div v-if="showExtra === 1" class="relative pad-top">
      <ul class="mini-tab right">
        <li class="active">Part 1</li>
        <li @click="showExtra = 2">Part 2</li>
      </ul>
      <img src="../../assets/slides/Slide30.jpeg" />
      <button
        style="position: absolute;
        bottom: 125px;
        right: 40px;"
        @click="showExtra = 3"
        class="button small rounded lower">
        Click here to find out what is<br />being called the 'hidden backlog'
      </button>
    </div>
    <div v-if="showExtra === 2" class="relative pad-top">
      <ul class="mini-tab right">
        <li @click="showExtra = 1">Part 1</li>
        <li class="active">Part 2</li>
      </ul>
      <img src="../../assets/slides/Slide31.jpeg" />
    </div>
    <div v-if="showExtra === 3" class="relative">
      <img src="../../assets/slides/Slide32.jpeg" />
      <button
        style="position: absolute;
        bottom: 125px;
        right: 40px;"
        @click="showExtra = 1"
        class="button small rounded lower">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTwoPageSeven',
  data() {
    return {
      showExtra: 1,
    };
  },
};
</script>
