<template>
  <div>    
    <img src="../../assets/slides/Slide23.jpeg" />    
  </div>
</template>
  
<script>
export default {
  name: 'ModuleTwoPageOne',
};
</script>
